<template>
    <el-page-header class="bottom10px" :title="' '" @back="goBack">
      <template #icon>
        <el-image style="width: 25px; height: 25px" src="./favicon.png" v-if="store.state.isHome"/>
        <el-icon v-if="!store.state.isHome"><ArrowLeft /></el-icon>
      </template>
      <template #content>
        <span class="text-large font-600 mr-3"> {{store.state.pageTitle}} </span>
      </template>
      <template #extra>
        <div class="flex items-center">
          <el-button class="ml-2" circle @click="aboutMe()"><el-icon><User /></el-icon></el-button>
        </div>
      </template>
    </el-page-header>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  ArrowLeft,
  User
} from '@element-plus/icons-vue'
import store from '../store'

export default defineComponent({
  name: 'HeaderBar'
})
</script>

<script lang="ts" setup>
const goBack = () => {
  store.state.mainPageName = store.state.goback
}
const aboutMe = () => {
  console.info('goback=' + store.state.goback)
  console.info('mainPageName=' + store.state.mainPageName)
  if (store.state.mainPageName !== 'aboutMe') {
    store.state.goback = store.state.mainPageName
  }
  store.state.mainPageName = 'aboutMe'
}
</script>
