import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)

app.mount('#app')

app.config.warnHandler = function (msg, vm, trace) {
  // 忽略特定警告
  if (msg.includes('Failed setting prop "children" on')) {
    return
  }
  // 默认的警告处理
  console.warn(msg, vm, trace)
}

console.info('main run')
