import { apiRootUrl } from '../config'
import store from '../store'

// fetch 获取数据
export async function apiFetchData (path: string) {
  const url = apiRootUrl + path
  console.log('apiFetchData url=', url)
  const response = await fetch(url)
  if (response.status === 302) {
    const redirectUrl = response.headers.get('Location')
    if (redirectUrl) {
      window.location.href = redirectUrl
      throw new Error('跳转到重定向地址') // 抛出异常中断逻辑
    }
  }
  const jsonData = await response.json()
  return jsonData
}

// 保存数据
export async function apiPostData (path: string, data: object) {
  const url = apiRootUrl + path
  console.log('apiFetchData url=', url)
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status === 302) {
        const redirectUrl = response.headers.get('Location')
        if (redirectUrl) {
          window.location.href = redirectUrl
          throw new Error('跳转到重定向地址') // 抛出异常中断逻辑
        }
      }
      return response.json()
    })
    .then(function (data) {
      console.log('apiPostData' + data)
      return data
    })
    .catch(error => {
      console.log('----------------------------------------------------')
      console.log('Error:', error)
      store.commit('setRequestResult', { message: '请求服务器失败!!' })
      console.log('****************************************************')
    })
}

export function getQueryParam (paramName: string) {
  // 获取当前URL
  const url = new URL(window.location.href)
  // 获取查询参数
  const searchParams = new URLSearchParams(url.search)
  // 获取特定参数的值
  return searchParams.get(paramName)
}
