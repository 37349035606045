<template>
  <div>
    <el-row :gutter="20" align="middle">
      <el-col :span="18">
      <el-input
          type="text"
          :prefix-icon="Tickets"
          v-model="refIccid"
          placeholder="请输入[iccid]"
          style="cursor: pointer"
          sytle="margin: 10px;"
      ></el-input>
      </el-col>
      <el-col :span="6" class="col-button">
      <el-button type="primary" plain @click="queryUsage()">查询</el-button>
      </el-col>
    </el-row>
    <template v-if="refData.code == '00000'">
      <template  v-for="item in refData.data" :key="item.order_id">
        <div class="card bottom10px" >
        <el-row class="card-row" :gutter="20" align="middle" >
          <el-col :span="8">
            <el-text class="mx-1"  type="primary">{{ item.time }}</el-text>
          </el-col>
          <el-col :span="8">
            <el-text class="mx-1"  type="primary">{{ item.country }}</el-text>
          </el-col>
          <el-col :span="8">
            <el-text class="mx-1" type="primary">{{item.qtaconsumption}}</el-text>
          </el-col>
        </el-row>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card bottom10px" >
        <el-row class="card-row" :gutter="20" align="middle">
            <el-col :span="24">
              <el-text class="mx-1" type="primary">{{ refData.message }}</el-text>
            </el-col>
        </el-row>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import {
  Tickets
} from '@element-plus/icons-vue'
import { apiPostData } from '../utils'

export default defineComponent({
  name: 'QueryOrderView'
})
</script>

<script lang="ts" setup>

const refIccid = ref('')
const refData = ref({ code: '00000', data: [] })

function queryUsage () {
  const data = { iccid: refIccid.value }
  apiPostData('/cmi/upcc-data-usage-query', data)
    .then(data => {
      if (data !== null && data !== undefined) {
        refData.value = data
      }
    })
}
</script>

<style scoped>
.card {
  background-color: rgb(243, 243, 243);
}
.card-row {
  text-align: left;
  align-items: center;
  padding: 5px; /* 可选的填充 */
  margin: 0px !important;
}
.col-title {
  text-align: right;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.col-button {
  text-align: right;
  padding-right: 20px !important;
}
</style>
