<template>
  <div>
    <template v-if="store.state.requestResult===null || store.state.requestResult===undefined">
      <h2>请求服务器失败</h2>
    </template>
    <template v-else>
      <h2>{{store.state.requestResult.message}}</h2>
    </template>
    <el-button  :style="{color: 'white'}" color="#76B873" type="success" round :dark=true @click="goHome()">回到首页</el-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import store from '../store'

export default defineComponent({
  name: 'BuyResultView'
})
</script>

<script lang="ts" setup>

const goHome = () => {
  store.state.mainPageName = 'home'
}
onMounted(async () => {
  store.state.pageTitle = '订购状态'
  store.state.isHome = false
  store.state.goback = 'buTraffic'
})
</script>
