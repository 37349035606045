<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-text tag="b" class="mx-1" size="large">激活esim卡/购买流量</el-text>
      </el-col>
    </el-row>
    <el-input
      class="bottom10px"
      type="text"
      :prefix-icon="Tickets"
      v-model="store.state.iccid"
      placeholder="请输入[iccid]"
      style="cursor: pointer"
      sytle="margin: 10px;"
    ></el-input>
    <div class="border-div bottom10px">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-text tag="b" class="mx-1" size="large">流量档位</el-text>
        </el-col>
      </el-row>
      <div v-for="item in refBundleList" :key="item">
        <el-text class="mx-1 text_center box" :class="{'selected-box': item['selected'] }" @click="selectBundle(item['bundle_id'])" >{{item.bundle_name}}</el-text>
      </div>
      <div style="text-align: right;">
        <el-button  :style="{color: 'white'}" color="#76B873" type="success" round :dark=true @click="buyTraffic()">购买&激活</el-button>
      </div>
      <!--
      <el-row :gutter="20">
        <el-col :span="24">
          <el-text tag="b" class="mx-1" size="large">加油包</el-text>
        </el-col>
      </el-row>
      <div v-for="item in refRefuelingList" :key="item">
          <el-text class="mx-1 text_center box" :class="{'selected-box': item['selected'] }" @click="selectRefueling(item['refueling_id'])">{{ item['name'] }}</el-text>
      </div>
      <div style="text-align: right;" v-if="refRefuelingList.length>0">
        <el-button  :style="{color: 'white'}" color="#76B873" type="success" round :dark=true @click="buyRefueling()">购买加油包</el-button>
      </div>
      -->
    </div>
    <div class="border-div">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-text tag="b" class="mx-1" size="large">上网说明</el-text>
        </el-col>
      </el-row>
      <el-row :gutter="20" justify="start">
        <el-col :span="24">
          <p>1. iccid在卡的背面，是20位数字，例如 898523**************</p>
          <p>2. 将SIM卡插入手机，等待约5分钟。如果您无法访问互联网，请关闭电源并再次打开手机。如果您还没有购买数据包，请先购买套餐。</p>
          <p>3. 订购成功后，你会收到短信通知。 </p>
          <p>4. 请确保您手机上的“移动数据”和“数据漫游”设置已打开。</p>
          <p>5. 请等待几分钟后访问Internet。如果失败，请重新启动手机并打开和关闭飞机模式，或手动设置APN为 cmlink。</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue'
import { apiPostData } from '../utils'
import {
  Tickets
} from '@element-plus/icons-vue'
import store from '../store'

export default defineComponent({
  name: 'BuyTrafficView'
})
</script>

<script lang="ts" setup>

const refBundleList = ref({})
const refRefuelingList = ref([])

onMounted(async () => {
  const enCountry = store.state.enCountry
  // 打印参数值
  console.log(enCountry)
  const countryBundleList = store.state.countryBundleList
  let country = {}
  for (let i = 0; i < countryBundleList.length; i++) {
    const countryList = countryBundleList[i].country_list
    for (let j = 0; j < countryList.length; j++) {
      if (countryList[j].en_country === enCountry) {
        country = countryList[j]
        break
      }
    }
  }
  refBundleList.value = country.bundle_list
  resetSelectedBundle()

  store.state.pageTitle = country.zh_country
  store.state.isHome = false
  store.state.goback = 'home'
})
function resetSelectedBundle () {
  for (let i = 0; i < refBundleList.value.length; i++) {
    refBundleList.value[i].selected = i === 0
  }
}
function selectBundle (bundleId) {
  console.log(bundleId)
  for (let i = 0; i < refBundleList.value.length; i++) {
    if (bundleId === refBundleList.value[i].bundle_id) {
      refBundleList.value[i].selected = true
      // refRefuelingList.value = refBundleList.value[i].refueling_list
    } else {
      refBundleList.value[i].selected = false
    }
  }
}
function selectRefueling (refuelingId) {
  console.info(refuelingId)
  for (let i = 0; i < refRefuelingList.value.length; i++) {
    if (refuelingId === refRefuelingList.value[i].refueling_id) {
      refRefuelingList.value[i].selected = true
    } else {
      refRefuelingList.value[i].selected = false
    }
  }
}
function getBuddleId () {
  for (let i = 0; i < refBundleList.value.length; i++) {
    if (refBundleList.value[i].selected) {
      return refBundleList.value[i].bundle_id
    }
  }
  return ''
}
function buyTraffic () {
  const buddleId = getBuddleId()
  const data = { iccid: store.state.iccid, data_bundle_id: buddleId }
  apiPostData('/cmi/order-sync', data)
    .then(data => {
      store.state.mainPageName = 'buyResult'
      if (data !== null && data !== undefined) {
        // apiPostData 内部 走到了 catch 的时候 data=undefined
        store.state.requestResult = data
      }
    })
}
function buyRefueling () {
  store.state.mainPageName = 'buyResult'
}
</script>

<style scoped>
.box {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 20px;
  text-align: left;
  border-radius: 4px;
  border-color:  rgba(200, 230, 190, 1);
  border: 1px solid lightgrey;
}
.selected-box {
  background-color:  rgba(228, 240, 222, 0.8);
}
.border-div {
    border: 1px solid lightgrey;
    padding: 10px; /* 可选，用于增加内容与边框之间的间距 */
}
</style>
