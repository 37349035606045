<template>
  <HeaderBar></HeaderBar>
  <div v-if="refShow">
    <HomeView v-if="store.state.mainPageName=='home'"></HomeView>
    <BuyTrafficView v-if="store.state.mainPageName=='buTraffic'"></BuyTrafficView>
    <BuyResultView v-if="store.state.mainPageName=='buyResult'"></BuyResultView>
    <AboutMeView v-if="store.state.mainPageName=='aboutMe'"></AboutMeView>
  </div>
  <div v-else>
    {{refTip}}
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue'
import HomeView from './HomeView.vue'
import BuyTrafficView from './BuyTrafficView.vue'
import BuyResultView from './BuyResultView.vue'
import AboutMeView from './AboutMeView.vue'
import store from '../store'
import HeaderBar from '../components/HeaderBar.vue'

export default defineComponent({
  name: 'MainView'
})
</script>

<script lang="ts" setup>
const refShow = ref(false)
const refTip = ref('正在初始化...')

onMounted(async () => {
  refShow.value = true
})
</script>
