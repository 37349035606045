<template>

  <div>
    <el-input
      class="bottom10px"
      type="text"
      :prefix-icon="Search"
      v-model="store.state.searchCountry"
      placeholder="请输入[国家名]"
      style="cursor: pointer"
      @input="handleSearch"
      sytle="margin: 10px;"
    ></el-input>
    <el-image class="bottom10px" src="./images/banner/banner02-350-200.png" fit="fill"/>
    <template v-for="group in refCountryBundleList" :key="group.group_name">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-text tag="b" class="mx-1" size="large">{{group.group_name}}</el-text>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="demo-radius" @click="selectCountry(item.en_country)" v-for="item in group.country_list" :key="item.en_country">
        <el-col :span="4">
          <div class="radius" :style="{ borderRadius: `var(--el-border-radius-base)` }">
            <el-image :src="item.flag_img"/>
          </div>
        </el-col>
        <el-col :span="16"><el-text class="mx-1">{{item.zh_country}}</el-text></el-col>
        <el-col :span="4"><el-text class="mx-1"><el-icon><ArrowRight /></el-icon></el-text></el-col>
      </el-row>
    </template>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue'
import {
  Search,
  ArrowRight
} from '@element-plus/icons-vue'
import store from '../store'
import { apiFetchData } from '../utils'

export default defineComponent({
  name: 'HomeView'
})
</script>

<script lang="ts" setup>

const refCountryBundleList = ref([])

function handleSearch () {
  console.log('搜索内容：', store.state.searchCountry)
  const countryBundleList = store.state.countryBundleList
  let curGroupName = ''
  refCountryBundleList.value = []
  for (let i = 0; i < countryBundleList.length; i++) {
    const groupName = countryBundleList[i].group_name
    const countryList = countryBundleList[i].country_list
    for (let j = 0; j < countryList.length; j++) {
      if (countryList[j].zh_country.indexOf(store.state.searchCountry) >= 0) {
        if (curGroupName !== groupName) {
          refCountryBundleList.value.push({
            group_name: groupName,
            country_list: []
          })
          curGroupName = groupName
        }
        refCountryBundleList.value[refCountryBundleList.value.length - 1].country_list.push(countryList[j])
      }
    }
  }
}

function selectCountry (enCountry) {
  store.state.mainPageName = 'buTraffic'
  store.state.enCountry = enCountry
}
onMounted(async () => {
  console.log('Homevie onMounted load data')
  store.state.pageTitle = 'Newtech Life Pte. Ltd'
  store.state.isHome = true

  if (store.state.countryBundleList.length > 0) {
    handleSearch()
    return
  }
  apiFetchData('/cmi/query-country-bundle')
    .then(data => {
      if (data === null || data === undefined) {
        console.log('请求出错了')
      } else {
        console.log('MainView onMounted load data')
        store.state.countryBundleList = data.data
        refCountryBundleList.value = store.state.countryBundleList
      }
    })
})
</script>

<style scoped>
.demo-radius .radius {
  display: flex;
  height: 30px;
  width: 40px;
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  margin-top: 0px;
  align-items: center;
}
</style>
