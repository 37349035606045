<template>
  <el-tabs type="border-card">
    <el-tab-pane label="查询订单">
      <QueryOrderView></QueryOrderView>
    </el-tab-pane>
    <el-tab-pane label="查询流量">
      <QueryUsageView></QueryUsageView>
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import store from '../store'
import QueryOrderView from './QueryOrderView.vue'
import QueryUsageView from './QueryUsageView.vue'

export default defineComponent({
  name: 'AboutMeView'
})
</script>

<script lang="ts" setup>

onMounted(async () => {
  store.state.pageTitle = '个人中心'
  store.state.isHome = false
})
</script>

<style scoped>
.card {
  background-color: rgb(243, 243, 243);
}
.card-row {
  text-align: left;
  align-items: center;
  padding: 5px; /* 可选的填充 */
  margin: 0px !important;
}
.col-title {
  text-align: right;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.col-button {
  text-align: right;
  padding-right: 20px !important;
}
</style>
