import { createStore } from 'vuex'

export default createStore({
  state: {
    mainPageName: 'home',
    enCountry: '',
    requestResult: {},

    pageTitle: '',
    isHome: true,
    goback: 'home',

    countryBundleList: {},
    searchCountry: '',
    iccid: ''
  },
  getters: {
  },
  mutations: {
    setRequestResult (state, result) {
      console.info(result)
      state.requestResult = result
    }
  },
  actions: {
  },
  modules: {
  }
})
